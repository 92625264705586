import { createSlice } from '@reduxjs/toolkit';
import { getCookie, setCookie } from 'cookies-next'; // Make sure to import the appropriate cookie library

const progressBar = getCookie("progress_bar")
const initialValue = {
  progressStep: progressBar ? progressBar : 0,
};

const progressBarSlice = createSlice({
  name: 'progressBar',
  initialState: { value: initialValue },
  reducers: {
    setProgressBar: (state, action) => {

      state.value.progressStep = action.payload.progressStep;
      // Setting admin details
      // state.value = {
      //   ...state.value.progressStep,
      //   progressStep: action.payload.progressStep,
      // };

      // Setting cookies
      setCookie("progress_bar", action.payload.progressStep, {
        expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
        path: "/"
      });
    },
  }
});

export const { setProgressBar } = progressBarSlice.actions;
export default progressBarSlice.reducer;
