import { createSlice } from '@reduxjs/toolkit';
import { deleteCookie, getCookie, setCookie } from 'cookies-next'; // Make sure to import the appropriate cookie library

const admin = getCookie("admin")
const adminAccessToken = getCookie("admin_access_token")
const intialValue = {
    admin: admin ? JSON.parse(admin) : null,
    adminAccessToken: adminAccessToken ? adminAccessToken : null,
};

const adminImpersonateSlice = createSlice({
    name: 'adminImpersonate',
    initialState: { value: intialValue },
    reducers: {
        startImpersonation: (state, action) => {
            // Setting admin details
            state.value.admin = action.payload.admin;

            // Setting admin access token
            state.value.adminAccessToken = action.payload.adminAccessToken;

            // Setting cookies
            setCookie("admin", action.payload.admin, {
                expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
                path: "/"
            });
            setCookie("admin_access_token", action.payload.adminAccessToken, {
                expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
                path: "/"
            });
        },
        stopImpersonation: (state) => {
            state.value.admin = null;
            state.value.adminAccessToken = null;
            deleteCookie("admin_access_token")
            deleteCookie("admin")
        }
    }
});

export const { startImpersonation, stopImpersonation } = adminImpersonateSlice.actions;
export default adminImpersonateSlice.reducer;
