import { configureStore } from "@reduxjs/toolkit";
import userLoginReducer from "./userLoginSlice.js";
import adminImpersonateSlice from "./adminImpersonateSlice.js";
import progressBarSlice from "./progress.bar.slice.js";

export const store = configureStore({
  reducer: {
    userToken: userLoginReducer,
    adminImpersonate: adminImpersonateSlice,
    progressBar: progressBarSlice
  },
});
